import type { ILiveDropBaseProperties } from '~/repository/amplitude/types/liveDrop.types';
import { ELiveDropEvents } from '~/repository/amplitude/types/liveDrop.types';
import type { PROVIDERS } from '~/composables/useAmplitude';

export class LiveDropEvents {
  static AMPLITUDE_KEY: keyof typeof PROVIDERS = 'DEFAULT';

  static profileClicked(eventProperties: ILiveDropBaseProperties) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: ELiveDropEvents.PROFILE_CLICKED,
      eventProperties,
    });
  }

  static itemClicked(eventProperties: ILiveDropBaseProperties) {
    return useAmplitude().sendEvent(this.AMPLITUDE_KEY, {
      event: ELiveDropEvents.ITEM_CLICKED,
      eventProperties,
    });
  }
}
