<template>
  <section v-if="selectedOffer" class="offer-controller">
    <div class="offer-controller__field">
      <PaymentFieldWithSelect
        class="offer-controller__fields-sum"
        :value="sumField.value"
        :country="{
          options: modifiedCountries,
          value: selectedCountry,
        }"
        is-disabled-input
        :currency-symbol="
          getIsFake ? Currency.DIAMOND : (currency?.symbol ?? Currency[defaultCurrency as TCurrencyType])
        "
        placeholder="0"
        @update:country-value="selectedCountry = $event"
      />
    </div>
    <div class="offer-controller__content">
      <div class="offer-controller__content-header">
        <h2 class="offer-controller__content-title">{{ $t('PaymentPage.get') }}</h2>
        <button class="offer-controller__content-title offer-controller__content-title--close" @click="handleClose">
          {{ $t('PaymentPage.close') }}
        </button>
      </div>

      <SecretShopOffer v-bind="selectedOffer" type="payment" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useCountriesStore } from '~/features/payment/store/countries.store';
import { useSumStore } from '~/features/payment/store/sum.store';
import { useCurrencyStore } from '~/features/payment/store/currency.store';
import { useCountryStore } from '~/features/payment/store/country.store';
import { useUserStore } from '~/store/user/user.store';

import { Currency } from '~/constants/currency.constants';
import type { TCurrencyType } from '~/types/Shared.types';
import { useOffersStore } from '~/features/secret-shop/store/offers.store';

const { defaultCurrency } = useProjectSettings();
const countriesStore = useCountriesStore();
const { countries } = storeToRefs(countriesStore);

const countryStore = useCountryStore();
const { selectedCountry } = storeToRefs(countryStore);

const sumStore = useSumStore();
const { sumField } = storeToRefs(sumStore);

const currencyStore = useCurrencyStore();
const { currency } = storeToRefs(currencyStore);

const userStore = useUserStore();
const { getIsFake } = storeToRefs(userStore);

const offersStore = useOffersStore();
const { selectedOffer } = storeToRefs(offersStore);

const { t } = useI18n();

const modifiedCountries = computed(() => {
  return countries.value.map((country) => {
    if (country.label === 'custom') {
      return {
        ...country,
        label: t('PaymentPage.other'),
      };
    }
    return country;
  });
});

const handleClose = () => {
  selectedOffer.value = null;
};

watch(
  selectedOffer,
  (newOffer) => {
    if (!newOffer) return;
    const newSum = newOffer.price.new;
    const newSumWithRate = Math.ceil(newSum * (currency.value?.rate ?? 1));
    sumField.value.value = `${newSumWithRate}`;
  },
  { deep: true, immediate: true },
);

watch(
  currency,
  (newCurrency) => {
    if (!newCurrency || !selectedOffer.value) return;
    const newSum = selectedOffer.value.price.new;
    const newSumWithRate = Math.ceil(newSum * (newCurrency.rate ?? 1));
    sumField.value.value = `${newSumWithRate}`;
  },
  { deep: true, immediate: true },
);
</script>

<style scoped lang="scss" src="./OfferController.scss" />
