import type {
  IAppRequest,
  IBasePaginationParams,
  IBasePaginationResponse,
  TCurrencyType,
  TPossibleNull,
} from '~/types/Shared.types';
import type { TDropItemType } from '~/types/cases/dropItem';
import type { IItemWsBk } from '~/features/bk/types/inventoryItems.types';
import type { ISimplifiedPreviewItem } from '~/features/cases/types/case.types';
import type { ICasesApiItemImage } from '~/features/mainPage/Types/cases/api.types';

export enum EGroupSortParams {
  ACTIVE = 'active',
  ALL = 'all',
  WITHDRAWAL = 'withdrawal',
  PROFILE = 'profile',
  UPGRADE = 'upgrade',
}

export interface IUnpackItemsRequestDTO {
  inventoryId: number;
}

export type IUnpackItemsResponseDTO = IAppRequest<IInventory[]>;

export type TGetInventoryRequestDTO = IBasePaginationParams & {
  group?: EGroupSortParams;
  userId?: number;
  all?: number;
};

export type TGetInventoryResponseDTO = IAppRequest<IResponseInventoryDTO>;

export interface IResponseInventoryDTO extends IBasePaginationResponse<IInventory> {
  totalPrice: string;
}

export interface IInventorySendItemResponseDTO {
  code: number;
  msg: string;
  status: boolean;
  untilSending?: number;
}

export interface IInventorySendItemResponseErrorDTO {
  code: string;
  parameters: {
    minimum: string;
  };
}

export type TInventorySendItemResponseDTO = IAppRequest<
  IInventorySendItemResponseDTO | IInventorySendItemResponseErrorDTO
>;

export interface IReplaceInventoryItemRequestDTO {
  inventoryId: number;
  replacementId: number;
}

export type TGetReplacementInventoryItemsRequestDTO = Omit<IReplaceInventoryItemRequestDTO, 'replacementId'>;

export type TGetReplacementInventoryItemsResponseDTO = IAppRequest<IItemWsBk[]>;

export interface IReplaceInventoryItemResponseDTO {
  msg: string;
  status: boolean;
}

export type TReplaceInventoryItemResponseDTO = IAppRequest<IReplaceInventoryItemResponseDTO>;

export interface ITradeInfo {
  tradeId: string;
  untilSending: string;
  untilTimeout: string;
}

export interface IInventoryFrom {
  battleUlid: TPossibleNull<number>;
  case: TPossibleNull<string>;
  from: EInventoryItemSource; // Поле с информацией об источнике предмета.
  caseImg: TPossibleNull<string>;
}

export type IInventoryPackContent = Omit<ISimplifiedPreviewItem, 'image' | 'quality'> & {
  qualityEnum: { name: string };
  imageData: ICasesApiItemImage;
  link: TPossibleNull<string>;
  tastyCoins: TPossibleNull<number[]>;
};

export interface IInventory {
  from?: IInventoryFrom;
  currency: TCurrencyType;
  id: number;
  img: string;
  isSelected?: boolean;
  isUltraRare?: boolean; // Является ли предмет Ультра-редким
  name: string;
  price: number;
  priceProfile: number;
  rare: string;
  rollId: number;
  status: EInventoryItemStatus;
  takeAvailable?: boolean;
  timerData?: number;
  // Состояние в котором находится предмет, необходим для флоу продажи/замены/вывода итд.
  type?: TDropItemType; // Тип предмета (Флаг для распознавания type3)
  replacementItems?: IInventory[];
  updateAt: string;
  trade?: ITradeInfo;
  parentPack?: TPossibleNull<number>;
  packItems?: TPossibleNull<IInventoryPackContent[]>;
  time?: number /* only for upgrades page inventory items */;
}

export enum EInventoryItemSource {
  BATTLES = 'Battle',
  CASES = 'Case',
  CONTRACTS = 'Contract',
  EXCHANGE = 'Replace',
  UPGRADE = 'Upgrade',
}

export enum EInventoryItemStatus {
  ACCEPTED = 'accepted', // вещь выведена на стим или баланс стима
  AUTOSELLED = 'autoselled', // вещь продана
  BAG = 'bag', // вещь забрана из-за абуза
  BUG = 'bug', // вещь забрана из-за абуза
  BEFORE = 'before', // статус вещи, после выбора предмета в pick_items
  CANCELED = 'canceled',
  CHANGED = 'changed', // вещь заменена при неудачной попытке вывода
  PROGRESS = 'progress', // вещь получена при дропе
  ERROR = 'error', // ошибка
  NO_LINK = 'no_link', // Некорректная ссылка
  SEND = 'send', // вещь отправлена, по пока не передана на стим
  SUPPORT = 'support', // вещь передана поддержкой, то же самое что progress
  SOLD = 'selled', // вещь продана
  UNPAID = 'unpaid', // вещь получена в кейс батлах, но недоступна, т.к. юзер не победил.
  CONFIRM_STEAM = 'CONFIRM_STEAM',
  DEFAULT = 'default',
  EXCHANGED = 'EXCHANGED',
  EXCHANGING = 'EXCHANGING',
  PREPARING = 'PREPARING',
  STEAM_WITHDRAWN = 'STEAM_WITHDRAWN',
  UPGRADED = 'to_upgrade', // вещь обменена по апгрейду
  CONTRACTED = 'to_contract', // вещь обменена по контракту
  VENDOR_WAITING = 'vendor waiting',
  COMPLETED = 'completed',
  UNPACKED = 'unpacked', // предмет, который является паком предметов распакован
}
