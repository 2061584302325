export function useCookieConsent() {
  const isConsentAccepted = ref(true);

  function acceptCookies() {
    isConsentAccepted.value = true;
    localStorage.setItem('tastyCookieConsent', 'true');
  }

  onBeforeMount(() => {
    isConsentAccepted.value = JSON.parse(localStorage.getItem('tastyCookieConsent') ?? 'false');
  });

  return {
    consent: isConsentAccepted,
    accept: acceptCookies,
  };
}
