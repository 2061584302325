import type { TPossibleNull } from '~/types/Shared.types';
import type { Colors } from '~/constants/colors.constants';
import type { IBattlesCaseEntity } from '~/repository/modules/battles/BattlesEntities.types';
import type { TDropItemType } from '~/types/cases/dropItem';

/**
 * Готовность участника
 * 0 - Не нажимал кнопки готовности или нажал отмену готовности
 * 1 - Нажал “Готов начать сейчас”
 * 2 - Нажал “Готов начать с ботами”
 */
export type TBattlesMemberReadyState = 0 | 1 | 2;

/**
 * Статус батла
 * Created - Батл только создан, еще ожидаем донабора игроков, могут быть
 *           внесены изменения (напр. добавлены боты, можно покинуть и пр.)
 * Started - Набрали игроков, изменения недоступны (нельзя выйти, войти и пр.),
 *           бэк проводит открытие, сколько-то раундов уже может быть подсчитано и готово
 *           к отображению, а сколько-то еще в процессе подсчета.
 * Finished - Все раунды сыграны, результаты известны и засчитаны
 * Cancelled - Создатель вышел из батла, тем самым отменив его
 */
export const enum EBattleStatuses {
  CANCELLED = 'cancelled',
  CREATED = 'created',
  FINISHED = 'finished',
  STARTED = 'started',
}

export type TBattleStatuses =
  | EBattleStatuses.CREATED
  | EBattleStatuses.STARTED
  | EBattleStatuses.FINISHED
  | EBattleStatuses.CANCELLED;

/* ID батла (вынесено отдельно для наследования в сущностях запросов) */
export interface IWithBattleUniqueId {
  battleUlid: string;
}

/* Лейбл кейса */
export interface ILabelOfCaseInBattle {
  /* Цвет HEX без # */
  color: TPossibleNull<string>;
  /* Каждое N открытие бесплатно */
  freeOpens: TPossibleNull<number>;
  /* Название лейбла (Хит продаж, новинка и др.) */
  name: TPossibleNull<string>;
  /* Тип лейбла (hot, new, upgrade и др.) */
  type: TPossibleNull<string>;
}

/* Скин в кейсе */
export interface ISkinItemInBattle {
  /* ID кейса */
  caseId: number;
  /* ID скина */
  id: number;
  /* URL изображения скина */
  image: string;
  /* Название скина */
  name: string;
  /* Уникальность скина */
  quality: Lowercase<keyof typeof Colors.RARITY>;
  /* Тип скина */
  type: TDropItemType;
}

/* Very Short Case in Battle For Create Battle */
export interface ICaseInBattle {
  amount: number;
  id: number;
}

/* Very Short Case in Battle In Responses */
export interface ICaseInBattleWithCaseId {
  amount: number;
  caseId: number;
  data: IBattlesCaseEntity;
}

/* Участник в батле */
export interface IMemberInBattle {
  /* URL аватара участника */
  avatar: TPossibleNull<string>;
  /* Отметка, что этот участник - бот */
  bot: boolean;
  /* ID участника */
  id: number;
  /* Уровень участника */
  level: TPossibleNull<number>;
  /* Отметка, что этот участник - текущий пользователь */
  me: boolean;
  /* Имя участника */
  name: string;
  /* Готовность участника */
  ready: TBattlesMemberReadyState;
  /* Номер слота, в котором находится участник */
  slot: number;
  /* ID пользователя, если это не бот */
  userId: TPossibleNull<number>;
}

/* Раунда в батле */
export interface IRoundInBattle {
  /* ID кейса */
  caseId: number;
  /* Время завершения раунда в мс (timestamp) */
  finishedAt: TPossibleNull<number>;
  /* Содержимое кейса в раунде */
  items: IItemInBattleRound[];
  /* Номер раунда */
  number: number;
  /* Результаты раунда в отдельных скинах (если раунд завершен) */
  prizes: TPossibleNull<IBattleRoundPrize[]>;
  /* Результаты раунда в деньгах по участникам (если раунд завершен) */
  results: IBattleAbsoluteResult[];
}

/* Скин в раунде (краткая информация) */
export interface IItemInBattleRound {
  /* ID скина */
  itemId: number;
  /* Цена скина */
  price: number;
}

/* Результат выигрыша в раундах и в самом батле */
export interface IBattleAbsoluteResult {
  /* Сумма койнов в отдельном раунде (батле) */
  coins: number;
  /* ID участника */
  memberId: number;
  /* Сумма скинов в деньгах, выпавших в раунде (батле) */
  total: number;
}

/* Приз в раунде (когда он завершился) */
export interface IBattleRoundPrize {
  item: TPossibleNull<IItemInBattleRound>;
  memberId: number;
}
