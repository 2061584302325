import type { ISharedKitButtonColorPreset } from '~/components/SharedKitButton/SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';
import { PaymentColors } from '~/features/payment/constants/colors.constants';

type TColorPresetsKeys =
  | 'SUM_PRESETS'
  | 'BLOGGER_UPGRADE'
  | 'BLOGGER_UPGRADE_ACTIVE'
  | 'PAY_BLOGGER'
  | 'PAY_BLOGGER_GRAY';

export const PaymentButtonPresets: Record<TColorPresetsKeys, ISharedKitButtonColorPreset> = {
  BLOGGER_UPGRADE: {
    defaultBackground: 'var(--main-color-200)',
    hoverBackground: 'var(--main-color-200)',
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  BLOGGER_UPGRADE_ACTIVE: {
    defaultBackground: Colors.ADDITIONAL.TRANSPARENT,
    hoverBackground: Colors.ADDITIONAL.TRANSPARENT,
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  PAY_BLOGGER: {
    defaultBackground: {
      colorStops: PaymentColors.PAYMENT_GRADIENT_LIGHTEN,
      toDirection: '140deg',
    },
    hoverBackground: {
      colorStops: PaymentColors.PAYMENT_GRADIENT_LIGHTEN_REVERSED,
      toDirection: '140deg',
    },
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  PAY_BLOGGER_GRAY: {
    defaultBackground: {
      colorStops: PaymentColors.PAYMENT_GRADIENT_LIGHTEN,
      toDirection: '140deg',
    },
    hoverBackground: 'var(--neutral-white)',
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: 'var(--neutral-black)',
  },
  SUM_PRESETS: {
    defaultBackground: Colors.ADDITIONAL.TRANSPARENT,
    hoverBackground: 'var(--main-color-200)',
    loadingColor: Colors.NEUTRAL.WHITE_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
};
