<script setup lang="ts">
import packsModal from 'assets/images/mainPage/packs-modal.png';
import type { IPacksPopupProps } from '~/features/mainPage/components/PacksPopup/PacksPopup.types';
import { PacksBannerButtonColorPreset } from '~/features/mainPage/components/PacksBanner/PacksBanner.data';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

withDefaults(defineProps<IPacksPopupProps>(), {
  modelValue: false,
});

const model = defineModel<boolean>('modelValue', {
  required: true,
});

const changeMD = GlobalUtils.Media.changeByMedia('md');
const eventCasesMainPageLink = ROUTING.MAINPAGE.MAINPAGE.path + '#event-cases';
</script>

<template>
  <SharedModal v-model="model" class="packs-popup">
    <div class="packs-popup__content">
      <NuxtImg :src="packsModal" provider="localProvider" class="packs-popup__header" />
      <div class="packs-popup__body">
        <div class="packs-popup__body-title">
          {{ $t('mainPage.packsPopup.title') }}
        </div>
        <div class="packs-popup__body-text">
          <p v-for="(item, i) in $tm('mainPage.packsPopup.textItems')" :key="i" class="packs-popup__body-text-item">
            <I18nT :keypath="'mainPage.packsPopup.textItems.' + i">
              <template #link1>
                <nuxt-link-locale :href="eventCasesMainPageLink" class="packs-popup__body-text-item--golden">
                  {{ $t('mainPage.packsPopup.link1') }}
                </nuxt-link-locale>
              </template>
            </I18nT>
          </p>
        </div>
        <SharedKitButton
          class="packs-popup__body-button"
          :text="$t('openCases')"
          :color-preset="PacksBannerButtonColorPreset"
          :size-preset="changeMD(SizePresets.L, SizePresets.M)"
          :type-preset="TypePresets.ROUND"
          :link-props="{
            to: eventCasesMainPageLink,
          }"
        />
      </div>
    </div>
  </SharedModal>
</template>

<style scoped lang="scss" src="./PacksPopup.scss" />
