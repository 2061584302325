import type {
  ISharedKitButtonColorPreset,
  ISharedKitButtonSizePreset,
  ISharedKitButtonTypePreset,
} from '~/components/SharedKitButton/SharedKitButton.types';

const changeByXl = GlobalUtils.Media.changeByMedia('xl');

export const acceptButtonColorPreset: ISharedKitButtonColorPreset = {
  defaultBackground: 'var(--neutral-white-15)',
  hoverBackground: 'var(--neutral-white-30)',
  loadingColor: 'var(--neutral-white-15)',
  textColor: 'white',
};

export const acceptButtonSizePreset = computed<ISharedKitButtonSizePreset>(() => ({
  fontSize: 14,
  height: changeByXl(40, 32),
  type: 'default',
}));

export const acceptButtonTypePreset = computed<ISharedKitButtonTypePreset>(() => ({
  default: changeByXl(8, 4),
}));
