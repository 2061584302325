import PromocodePopupRepository from '../api/repository';
import type { IPromoInfo } from '../types/promo/client.types';

export const useInfoStore = defineStore('promocode-popup/info', () => {
  const info = ref<IPromoInfo>();
  const parsedPromo = ref<string>();

  const getInfo = async (promocode: string) => {
    if (!promocode) return;

    try {
      info.value = await PromocodePopupRepository.getInfo(promocode);

      if (!info.value) return;
      parsedPromo.value = promocode;
    } catch {}
  };

  return {
    info,
    getInfo,
    parsedPromo,
  };
});
