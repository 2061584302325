<template>
  <div
    :style="{ background: props.background }"
    :class="['base-drop', { 'base-drop--ultra': isUltraRare, hasMultiHover }]"
  >
    <div v-if="shining" class="base-drop__shinner" :style="shiningColorVariable" />
    <div class="base-drop__content">
      <slot name="image"></slot>
      <div class="base-drop__line base-drop__line-top">
        <div class="base-drop__icon base-drop__icon-left">
          <slot name="top-left-icon" />
        </div>
        <slot name="upgrade-percent" />
        <div class="base-drop__icon base-drop__icon-right">
          <slot name="top-right-icon" />
        </div>
      </div>
      <div class="base-drop__line base-drop__line-bottom">
        <div class="base-drop__icon base-drop__icon-left">
          <slot name="bottom-left-icon" />
        </div>
        <div class="base-drop__icon base-drop__icon-right">
          <slot name="bottom-right-icon" />
        </div>
      </div>
    </div>
    <div class="base-drop__hover">
      <NuxtLinkLocale :to="itemLink" class="base-drop__link" @click="onSourceRedirect">
        <img class="base-drop__hover-image" :src="props.hoverImage" loading="lazy" alt="hover-img" />
      </NuxtLinkLocale>
      <NuxtLinkLocale :to="userLink" class="base-drop__link" @click="onProfileRedirect">
        <button class="base-drop__hover-user">{{ props.user.nickname }}</button>
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<script setup lang="ts">
import CaseHover from '~/assets/images/Case/case-img.png';

import type { IBaseDropProps } from '~/components/BaseDrop/BaseDrop.types';
import { LiveDropEvents } from '~/repository/amplitude/events/liveDrop';
import type { TItemSource } from '~/repository/amplitude/types/liveDrop.types';

const props = withDefaults(defineProps<IBaseDropProps>(), {
  hoverImage: CaseHover,
  hasMultiHover: false,
});

const userLink = computed(() => `${ROUTING.PROFILE.USER_PAGE.getDynamicPath(String(props.user.id ?? ''))}`);

const shiningColorVariable = computed(() => ({ '--shining-color': props.shiningColor }));

const amplitudeItemSource = computed<TItemSource>(() => {
  const type = props.type?.toLowerCase();
  return type && ['contract', 'upgrade', 'battle'].includes(type) ? (type as TItemSource) : 'case';
});

const onSourceRedirect = () => {
  LiveDropEvents.itemClicked({ 'Item Source': amplitudeItemSource.value });
};
const onProfileRedirect = () => {
  LiveDropEvents.profileClicked({ 'Item Source': amplitudeItemSource.value });
};
</script>

<style scoped lang="scss" src="./BaseDrop.scss" />
