<template>
  <div class="countdown" :style="rootStyles">
    <I18nT tag="div" class="countdown__text" keypath="promocodePopup.countdown">
      <template #time>
        <span class="countdown__text-time">{{ parsedTime }}</span>
      </template>
    </I18nT>
    <div class="countdown__time"></div>
  </div>
</template>

<script setup lang="ts">
import { POPUP_TIME_IN_SEC } from '../../constants/countdown';
import type { ICountdownEmits } from './Countdown.types';

const emit = defineEmits<ICountdownEmits>();

const remainingTime = ref(POPUP_TIME_IN_SEC);

const parsedTime = computed(() => {
  const minutes = Math.floor(remainingTime.value / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (remainingTime.value % 60).toString().padStart(2, '0');

  return `${minutes}:${seconds}`;
});

const rootStyles = computed(() => {
  return {
    '--remaining-time': `${(remainingTime.value / POPUP_TIME_IN_SEC) * 100}%`,
  };
});

let interval: ReturnType<typeof setInterval> | undefined;

onMounted(() => {
  interval = setInterval(() => {
    if (remainingTime.value <= 0) {
      clearInterval(interval);
      emit('end');
      return;
    }

    remainingTime.value--;
  }, 1000);
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<style scoped lang="scss" src="./Countdown.scss"></style>
