import { useAuthStore } from '~/features/authentication/store/authorization';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';

const HASH_TRIGGER = '#reg';

export const useHashAuth = () => {
  const authStore = useAuthStore();
  const { isAuth } = storeToRefs(authStore);

  const authPopupStore = useAuthPopupStore();

  const route = useRoute();

  watch(
    () => route.hash,
    (hash) => {
      if (!hash.includes(HASH_TRIGGER) || isAuth.value) return;
      authPopupStore.open();
    },
    { immediate: true },
  );
};
