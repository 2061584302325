import { default as MainPagen6Nc6pyRpmMeta } from "~/project-layers/cs/routes/techies/MainPage/MainPage.vue?macro=true";
import { default as IFrameControllersb6Vw7jHvyMeta } from "~/features/bk/controllers/IFrameController/IFrameController.vue?macro=true";
import { default as IFrameTestController4SIoFoByljMeta } from "~/features/bk/controllers/IFrameTestController/IFrameTestController.vue?macro=true";
import { default as DropPageRI2JYDoo6QMeta } from "/app/project-layers/cs/routes/cases/DropPage/DropPage.vue?macro=true";
import { default as OpenPageSilmJoM19AMeta } from "/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue?macro=true";
import { default as DailyCasesSPq3H9tD23Meta } from "/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue?macro=true";
import { default as Landing0Tgd6mHfgCMeta } from "~/project-layers/cs/routes/hamster/Landing/Landing.vue?macro=true";
import { default as EventConditionPageYqGREAtYDvMeta } from "/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue?macro=true";
import { default as PrivacyPolicyPage1wuYbvFmreMeta } from "/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue?macro=true";
import { default as CookiesPagellDKG42GL7Meta } from "/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue?macro=true";
import { default as ContactsPage0iVO1QAjM1Meta } from "/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue?macro=true";
import { default as UserAgreementPageFiEBrVzJDpMeta } from "/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue?macro=true";
import { default as MainPage1AfDoi2xshMeta } from "/app/project-layers/cs/routes/faq/MainPage/MainPage.vue?macro=true";
import { default as MainPageGANzhcXoh3Meta } from "/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue?macro=true";
import { default as EventCasesPageMfbu9Mm98cMeta } from "/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue?macro=true";
import { default as TopLaddersPageSpKGIbjXPOMeta } from "/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue?macro=true";
import { default as MainPageOr8Dl8ssrNMeta } from "/app/project-layers/cs/routes/payment/MainPage/MainPage.vue?macro=true";
import { default as ResultPageJZr3d6XqqEMeta } from "/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue?macro=true";
import { default as UnauthPage7cRgYONdBGMeta } from "/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue?macro=true";
import { default as test5kzXGll1HeMeta } from "~/project-layers/cs/routes/test/test.vue?macro=true";
import { default as UserPageGpaSNnZRNyMeta } from "/app/project-layers/cs/routes/profile/UserPage/UserPage.vue?macro=true";
import { default as InstructionsPageZEjUBBfrHdMeta } from "/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue?macro=true";
import { default as SettingsPagetKGuHilRVlMeta } from "/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue?macro=true";
import { default as CheckPage0WKs6TmHM6Meta } from "/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue?macro=true";
import { default as CaseHistoryPagexdbEtBxssuMeta } from "/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue?macro=true";
import { default as MainPageZdKw74nOblMeta } from "/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue?macro=true";
import { default as MainPagezNuoVWkJOgMeta } from "/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue?macro=true";
import { default as UserPagezYMO7v0p2qMeta } from "/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue?macro=true";
export default [
  {
    name: "ninja-defuse___en",
    path: "/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "ninja-defuse___ru",
    path: "/ru/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "ninja-defuse___kk",
    path: "/kk/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "ninja-defuse___az",
    path: "/az/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "ninja-defuse___uz",
    path: "/uz/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "ninja-defuse___es",
    path: "/es/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "ninja-defuse___hi",
    path: "/hi/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "ninja-defuse___ph",
    path: "/ph/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "ninja-defuse___id",
    path: "/id/ninja-defuse",
    meta: MainPagen6Nc6pyRpmMeta || {},
    component: () => import("~/project-layers/cs/routes/techies/MainPage/MainPage.vue")
  },
  {
    name: "iframe-data___en",
    path: "/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___ru",
    path: "/ru/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___kk",
    path: "/kk/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___az",
    path: "/az/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___uz",
    path: "/uz/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___es",
    path: "/es/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___hi",
    path: "/hi/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___ph",
    path: "/ph/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-data___id",
    path: "/id/integrator/:integrator_uuid/iframe/case/:case_name",
    meta: { ...(IFrameControllersb6Vw7jHvyMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameController/IFrameController.vue")
  },
  {
    name: "iframe-testing___en",
    path: "/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___ru",
    path: "/ru/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___kk",
    path: "/kk/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___az",
    path: "/az/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___uz",
    path: "/uz/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___es",
    path: "/es/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___hi",
    path: "/hi/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___ph",
    path: "/ph/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "iframe-testing___id",
    path: "/id/integrator/:integrator_uuid/iframe/test",
    meta: { ...(IFrameTestController4SIoFoByljMeta || {}), ...{"isIntegratorModule":true} },
    component: () => import("~/features/bk/controllers/IFrameTestController/IFrameTestController.vue")
  },
  {
    name: "drop-page___en",
    path: "/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___ru",
    path: "/ru/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___kk",
    path: "/kk/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___az",
    path: "/az/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___uz",
    path: "/uz/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___es",
    path: "/es/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___hi",
    path: "/hi/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___ph",
    path: "/ph/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "drop-page___id",
    path: "/id/open/:caseName/:gameId",
    component: () => import("/app/project-layers/cs/routes/cases/DropPage/DropPage.vue")
  },
  {
    name: "open-page___en",
    path: "/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "open-page___ru",
    path: "/ru/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "open-page___kk",
    path: "/kk/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "open-page___az",
    path: "/az/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "open-page___uz",
    path: "/uz/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "open-page___es",
    path: "/es/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "open-page___hi",
    path: "/hi/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "open-page___ph",
    path: "/ph/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "open-page___id",
    path: "/id/open/:caseName",
    component: () => import("/app/project-layers/cs/routes/cases/OpenPage/OpenPage.vue")
  },
  {
    name: "daily-cases___en",
    path: "/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___ru",
    path: "/ru/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___kk",
    path: "/kk/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___az",
    path: "/az/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___uz",
    path: "/uz/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___es",
    path: "/es/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___hi",
    path: "/hi/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___ph",
    path: "/ph/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "daily-cases___id",
    path: "/id/daily_cases",
    component: () => import("/app/project-layers/cs/routes/cases/DailyCases/DailyCases.vue")
  },
  {
    name: "hamster___en",
    path: "/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___ru",
    path: "/ru/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___kk",
    path: "/kk/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___az",
    path: "/az/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___uz",
    path: "/uz/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___es",
    path: "/es/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___hi",
    path: "/hi/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___ph",
    path: "/ph/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "hamster___id",
    path: "/id/hamster",
    meta: Landing0Tgd6mHfgCMeta || {},
    component: () => import("~/project-layers/cs/routes/hamster/Landing/Landing.vue")
  },
  {
    name: "event-condition___en",
    path: "/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___ru",
    path: "/ru/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___kk",
    path: "/kk/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___az",
    path: "/az/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___uz",
    path: "/uz/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___es",
    path: "/es/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___hi",
    path: "/hi/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___ph",
    path: "/ph/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "event-condition___id",
    path: "/id/eventCondition",
    meta: { ...(EventConditionPageYqGREAtYDvMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/EventConditionPage/EventConditionPage.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___ru",
    path: "/ru/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___kk",
    path: "/kk/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___az",
    path: "/az/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___uz",
    path: "/uz/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___es",
    path: "/es/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___hi",
    path: "/hi/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___ph",
    path: "/ph/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "privacy-policy___id",
    path: "/id/privacy-policy",
    meta: { ...(PrivacyPolicyPage1wuYbvFmreMeta || {}), ...{"layout":"footer-pages","layoutClass":"event"} },
    component: () => import("/app/project-layers/cs/routes/footer-pages/PrivacyPolicyPage/PrivacyPolicyPage.vue")
  },
  {
    name: "cookies___en",
    path: "/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___ru",
    path: "/ru/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___kk",
    path: "/kk/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___az",
    path: "/az/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___uz",
    path: "/uz/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___es",
    path: "/es/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___hi",
    path: "/hi/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___ph",
    path: "/ph/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "cookies___id",
    path: "/id/cookies",
    meta: {"layout":"footer-pages","layoutClass":"cookies"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/CookiesPage/CookiesPage.vue")
  },
  {
    name: "contacts___en",
    path: "/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___ru",
    path: "/ru/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___kk",
    path: "/kk/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___az",
    path: "/az/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___uz",
    path: "/uz/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___es",
    path: "/es/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___hi",
    path: "/hi/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___ph",
    path: "/ph/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "contacts___id",
    path: "/id/contacts",
    meta: {"layout":"footer-pages","layoutClass":"contacts"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/ContactsPage/ContactsPage.vue")
  },
  {
    name: "user-agreement___en",
    path: "/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___ru",
    path: "/ru/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___kk",
    path: "/kk/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___az",
    path: "/az/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___uz",
    path: "/uz/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___es",
    path: "/es/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___hi",
    path: "/hi/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___ph",
    path: "/ph/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "user-agreement___id",
    path: "/id/userAgreement",
    meta: {"layout":"footer-pages","layoutClass":"agreement"},
    component: () => import("/app/project-layers/cs/routes/footer-pages/UserAgreementPage/UserAgreementPage.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___kk",
    path: "/kk/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___az",
    path: "/az/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___uz",
    path: "/uz/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___es",
    path: "/es/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___hi",
    path: "/hi/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___ph",
    path: "/ph/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "faq___id",
    path: "/id/faq",
    meta: MainPage1AfDoi2xshMeta || {},
    component: () => import("/app/project-layers/cs/routes/faq/MainPage/MainPage.vue")
  },
  {
    name: "main-page___en",
    path: "/",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___ru",
    path: "/ru",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___kk",
    path: "/kk",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___az",
    path: "/az",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___uz",
    path: "/uz",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___es",
    path: "/es",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___hi",
    path: "/hi",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___ph",
    path: "/ph",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "main-page___id",
    path: "/id",
    meta: MainPageGANzhcXoh3Meta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/MainPage/MainPage.vue")
  },
  {
    name: "new-cases___en",
    path: "/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___ru",
    path: "/ru/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___kk",
    path: "/kk/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___az",
    path: "/az/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___uz",
    path: "/uz/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___es",
    path: "/es/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___hi",
    path: "/hi/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___ph",
    path: "/ph/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "new-cases___id",
    path: "/id/new-cases",
    meta: EventCasesPageMfbu9Mm98cMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/EventCasesPage/EventCasesPage.vue")
  },
  {
    name: "top___en",
    path: "/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___ru",
    path: "/ru/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___kk",
    path: "/kk/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___az",
    path: "/az/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___uz",
    path: "/uz/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___es",
    path: "/es/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___hi",
    path: "/hi/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___ph",
    path: "/ph/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "top___id",
    path: "/id/top",
    meta: TopLaddersPageSpKGIbjXPOMeta || {},
    component: () => import("/app/project-layers/cs/routes/mainPage/TopLaddersPage/TopLaddersPage.vue")
  },
  {
    name: "payment___en",
    path: "/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___ru",
    path: "/ru/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___kk",
    path: "/kk/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___az",
    path: "/az/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___uz",
    path: "/uz/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___es",
    path: "/es/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___hi",
    path: "/hi/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___ph",
    path: "/ph/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment___id",
    path: "/id/refill",
    meta: MainPageOr8Dl8ssrNMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/MainPage/MainPage.vue")
  },
  {
    name: "payment-result___en",
    path: "/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___ru",
    path: "/ru/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___kk",
    path: "/kk/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___az",
    path: "/az/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___uz",
    path: "/uz/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___es",
    path: "/es/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___hi",
    path: "/hi/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___ph",
    path: "/ph/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-result___id",
    path: "/id/refill/result",
    meta: ResultPageJZr3d6XqqEMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/ResultPage/ResultPage.vue")
  },
  {
    name: "payment-unauth___en",
    path: "/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___ru",
    path: "/ru/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___kk",
    path: "/kk/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___az",
    path: "/az/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___uz",
    path: "/uz/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___es",
    path: "/es/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___hi",
    path: "/hi/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___ph",
    path: "/ph/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "payment-unauth___id",
    path: "/id/refill/unauth",
    meta: UnauthPage7cRgYONdBGMeta || {},
    component: () => import("/app/project-layers/cs/routes/payment/UnauthPage/UnauthPage.vue")
  },
  {
    name: "test___en",
    path: "/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "test___ru",
    path: "/ru/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "test___kk",
    path: "/kk/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "test___az",
    path: "/az/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "test___uz",
    path: "/uz/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "test___es",
    path: "/es/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "test___hi",
    path: "/hi/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "test___ph",
    path: "/ph/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "test___id",
    path: "/id/test",
    meta: test5kzXGll1HeMeta || {},
    component: () => import("~/project-layers/cs/routes/test/test.vue")
  },
  {
    name: "profile___en",
    path: "/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___ru",
    path: "/ru/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___kk",
    path: "/kk/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___az",
    path: "/az/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___uz",
    path: "/uz/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___es",
    path: "/es/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___hi",
    path: "/hi/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___ph",
    path: "/ph/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___id",
    path: "/id/profile/:userId",
    meta: UserPageGpaSNnZRNyMeta || {},
    component: () => import("/app/project-layers/cs/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "provably-fair-instructions___en",
    path: "/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___ru",
    path: "/ru/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___kk",
    path: "/kk/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___az",
    path: "/az/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___uz",
    path: "/uz/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___es",
    path: "/es/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___hi",
    path: "/hi/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___ph",
    path: "/ph/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-instructions___id",
    path: "/id/provably-fair/instructions",
    meta: {"layout":"provably-fair"},
    component: () => import("/app/project-layers/cs/routes/provably-fair/InstructionsPage/InstructionsPage.vue")
  },
  {
    name: "provably-fair-settings___en",
    path: "/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___ru",
    path: "/ru/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___kk",
    path: "/kk/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___az",
    path: "/az/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___uz",
    path: "/uz/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___es",
    path: "/es/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___hi",
    path: "/hi/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___ph",
    path: "/ph/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-settings___id",
    path: "/id/provably-fair/settings",
    meta: { ...(SettingsPagetKGuHilRVlMeta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/SettingsPage/SettingsPage.vue")
  },
  {
    name: "provably-fair-check___en",
    path: "/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___ru",
    path: "/ru/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___kk",
    path: "/kk/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___az",
    path: "/az/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___uz",
    path: "/uz/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___es",
    path: "/es/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___hi",
    path: "/hi/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___ph",
    path: "/ph/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-check___id",
    path: "/id/provably-fair/check",
    meta: { ...(CheckPage0WKs6TmHM6Meta || {}), ...{"layout":"provably-fair"} },
    component: () => import("/app/project-layers/cs/routes/provably-fair/CheckPage/CheckPage.vue")
  },
  {
    name: "provably-fair-case-history___en",
    path: "/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___ru",
    path: "/ru/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___kk",
    path: "/kk/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___az",
    path: "/az/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___uz",
    path: "/uz/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___es",
    path: "/es/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___hi",
    path: "/hi/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___ph",
    path: "/ph/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "provably-fair-case-history___id",
    path: "/id/provably-fair/case-history/:caseId",
    meta: {"layout":"provably-fair","hideMenu":true},
    component: () => import("/app/project-layers/cs/routes/provably-fair/CaseHistoryPage/CaseHistoryPage.vue")
  },
  {
    name: "secret-shop___en",
    path: "/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___ru",
    path: "/ru/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___kk",
    path: "/kk/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___az",
    path: "/az/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___uz",
    path: "/uz/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___es",
    path: "/es/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___hi",
    path: "/hi/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___ph",
    path: "/ph/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "secret-shop___id",
    path: "/id/offersshop",
    meta: MainPageZdKw74nOblMeta || {},
    component: () => import("/app/project-layers/cs/routes/secret-shop/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___en",
    path: "/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___ru",
    path: "/ru/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___kk",
    path: "/kk/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___az",
    path: "/az/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___uz",
    path: "/uz/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___es",
    path: "/es/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___hi",
    path: "/hi/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___ph",
    path: "/ph/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "upgrades___id",
    path: "/id/upgrades",
    component: () => import("/app/project-layers/cs/routes/upgrades/MainPage/MainPage.vue")
  },
  {
    name: "profile___en",
    path: "/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___ru",
    path: "/ru/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___kk",
    path: "/kk/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___az",
    path: "/az/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___uz",
    path: "/uz/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___es",
    path: "/es/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___hi",
    path: "/hi/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___ph",
    path: "/ph/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile___id",
    path: "/id/profile/:userId",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___en",
    path: "/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___ru",
    path: "/ru/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___kk",
    path: "/kk/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___az",
    path: "/az/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___uz",
    path: "/uz/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___es",
    path: "/es/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___hi",
    path: "/hi/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___ph",
    path: "/ph/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  },
  {
    name: "profile-me___id",
    path: "/id/profile",
    meta: UserPagezYMO7v0p2qMeta || {},
    component: () => import("/app/project-layers/cs-global/routes/profile/UserPage/UserPage.vue")
  }
]