import type { IPromoInfo } from '../types/promo/client.types';
import { $authApi } from '~/api/global/ApiProvider';
import type { TApiPromo, IApiErrorPromo } from '~/features/payment/types/promo/api.types';
import { transform } from '../types/promo/adapter';
import type { IAppRequest } from '~/types/Shared.types';

export default class PromocodePopupRepository {
  private static API_URL = '/v1/payments/promo/info/guest';

  public static async getInfo(promocode: string): Promise<IPromoInfo | undefined> {
    const response = await $authApi.get<IAppRequest<TApiPromo | IApiErrorPromo>>(`${this.API_URL}/${promocode}`);

    if (!response.status) return undefined;
    return transform(response.data);
  }
}
