<template>
  <section class="techies-page">
    <div class="techies-page__logo">
      <NuxtImg class="techies-page__logo-image" :src="logo" loading="lazy" provider="localProvider" />
    </div>
    <TechiesInstructionsController class="techies-page__instructions" />
    <ClientOnly>
      <TechiesButtonBlock
        :loading="isStartLoading"
        :shuffle-loading="isShuffling"
        :state="buttonBlockState"
        class="techies-page__button-block"
        @auth="authStore.oauth"
        @start="infoStore.startGame($event)"
        @shuffle="infoStore.shuffle"
        @hover="mapStore.handleHover"
        @leave="mapStore.handleLeave"
      />
    </ClientOnly>
    <section class="techies-page__content">
      <div class="techies-page__content-game">
        <TechiesStagesBarController class="techies-page__content-game__custom-stages" />
        <TechiesGameMapController />
        <TechiesStagesBarController />
      </div>
      <TechiesFinalPrize class="techies-page__content-prize" :is-end="isWon" />
    </section>
  </section>
</template>

<script setup lang="ts">
import { EButtonBlockStates } from '~/features/techies/components/ButtonBlock/ButtonBlock.types';

import { useAuthStore } from '~/features/authentication/store/authorization';
import { useVolumeStore } from '~/store/volume/volume';
import { volumesKeys } from '~/project-layers/cs/store/volume/volumes.data';

import { useInfoStore } from '~/project-layers/cs/features/techies/store/info.store';
import { useMapStore } from '~/features/techies/store/map.store';

definePageMeta({
  layout: 'techies',
});

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const infoStore = useInfoStore();
const { isWon, isGameStarted, isShuffling, isStartLoading } = storeToRefs(infoStore);

const mapStore = useMapStore();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

if (isAuth.value) {
  await useAsyncData('techies-first-info', () => infoStore.getInfo(), { server: false });
}

const logo = computed(() => getS3Image('techies', changeXL('logo', 'logo-m')));

const buttonBlockState = computed<EButtonBlockStates>(() => {
  if (!isAuth.value) return EButtonBlockStates.UNAUTH;
  if (isGameStarted.value) return EButtonBlockStates.INGAME;
  return EButtonBlockStates.DEFAULT;
});

const volumeStore = useVolumeStore();
onMounted(() => {
  volumeStore.loadVolume(volumesKeys.techiesLose);
  volumeStore.loadVolume(volumesKeys.techiesClick);
  volumeStore.loadVolume(volumesKeys.techiesWin);
});
</script>

<style scoped lang="scss" src="./MainPage.scss" />
