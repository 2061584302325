import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import components_client_ZB1W0EeH0E from "/app/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_client_4F1kZh3YAB from "/app/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_ghbUAjaD3n from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_UC48QI1yOB from "/app/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import plugin_3G0UZgWcDU from "/app/node_modules/nuxt-utm/dist/runtime/plugin.mjs";
import routing_9DCz4BdToa from "/app/features/battles/plugins/routing.ts";
import useAmplitude_client_XkjlUjA7C0 from "/app/plugins/useAmplitude.client.ts";
import useApi_P5ldxlW7np from "/app/plugins/useApi.ts";
import useApp_w4Olt1izBQ from "/app/plugins/useApp.ts";
import useLocalization_client_lk2036Ut9M from "/app/plugins/useLocalization.client.ts";
import useRedirectToRefillAfterLoginWhenBalanceIs0_client_7Rg3p2aoBn from "/app/plugins/useRedirectToRefillAfterLoginWhenBalanceIs0.client.ts";
import useTriggerOffer_client_xitCygv4Lu from "/app/plugins/useTriggerOffer.client.ts";
import useUTM_client_nBUau91V3A from "/app/plugins/useUTM.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  components_client_ZB1W0EeH0E,
  plugin_client_4F1kZh3YAB,
  plugin_ghbUAjaD3n,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_UC48QI1yOB,
  plugin_3G0UZgWcDU,
  routing_9DCz4BdToa,
  useAmplitude_client_XkjlUjA7C0,
  useApi_P5ldxlW7np,
  useApp_w4Olt1izBQ,
  useLocalization_client_lk2036Ut9M,
  useRedirectToRefillAfterLoginWhenBalanceIs0_client_7Rg3p2aoBn,
  useTriggerOffer_client_xitCygv4Lu,
  useUTM_client_nBUau91V3A
]