export enum EPromocodePopupEvents {
  CLAIM_BONUS_CLICKED = 'Promo - Claim Bonus Clicked',
  POPUP_CLOSED = 'Promo - Pop-up Closed',
}

export interface IPromocodePopupUnifiedProps {
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
}
