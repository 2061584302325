<template>
  <BaseModal v-if="route.query.utm_content" v-model="showModal" class="content-controller">
    <h2 class="content-controller__title">
      {{ $t('promocodePopup.title') }}
    </h2>
    <div class="content-controller__promocode">{{ amplitudeData.utm_content }}</div>
    <div class="content-controller__information">
      <span class="content-controller__information-default">{{ $t('promocodePopup.use') }}</span>
      <span class="content-controller__information-reward">
        {{ rewardText }}
      </span>
    </div>
    <div class="content-controller__button">
      <SharedKitButton
        :text="$t('promocodePopup.getBonus')"
        :color-preset="ColorPresets.ACCENT"
        :size-preset="SizePresets.L"
        :type-preset="TypePresets.RECTANGLE"
        @click="handleClaim()"
      />
    </div>
    <PromocodePopupCountdown class="content-controller__countdown" @end="handleClaim(true)" />
  </BaseModal>
</template>

<script setup lang="ts">
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useAuthPopupStore } from '~/features/general-auth/store/popup.store';
import { useInfoStore } from '~/features/promocode-popup/store/info.store';
import { PromocodePopupEvents } from '~/repository/amplitude/events/promocode-popup';

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const authPopupStore = useAuthPopupStore();
const { isOpen: authPopupOpen } = storeToRefs(authPopupStore);

const infoStore = useInfoStore();
const { info } = storeToRefs(infoStore);

const route = useRoute();

const showModal = ref(false);
const isClaimed = ref(false);

const { t } = useNuxtApp().$i18n;

onMounted(async () => {
  if (!amplitudeData.value.utm_content) return;
  await infoStore.getInfo(amplitudeData.value.utm_content);

  const baseName = useRouteBaseName()(route) ?? '';
  const paymentNames = [ROUTING.PAYMENT.MAIN.name, ROUTING.PAYMENT.UNAUTH.name, ROUTING.PAYMENT.RESULT.name];

  if (!info.value || paymentNames.includes(baseName)) return;
  showModal.value = true;
});

const rewardText = computed(() => {
  if (!info.value) return '';

  const i18nKey = info.value.isBlogger ? 'rewardWithCase' : 'defaultReward';
  return t(`promocodePopup.${i18nKey}`, { n: info.value.percent });
});

const amplitudeData = computed(() => ({
  utm_content: `${route.query.utm_content ?? ''}`,
  utm_source: `${route.query.utm_source ?? ''}`,
  utm_medium: `${route.query.utm_medium ?? ''}`,
  utm_campaign: `${route.query.utm_campaign ?? ''}`,
  utm_term: `${route.query.utm_term ?? ''}`,
}));

const handleClaim = (isTimeEnd = false) => {
  isClaimed.value = true;
  showModal.value = false;

  if (!isTimeEnd) {
    PromocodePopupEvents.claimBonusClicked(amplitudeData.value);
  }

  if (!isAuth.value) {
    authPopupOpen.value = true;
    return;
  }

  return navigateTo(useLocaleRoute()(ROUTING.PAYMENT.MAIN));
};

watch(showModal, (value) => {
  if (value || isClaimed.value) return;

  PromocodePopupEvents.popupClosed(amplitudeData.value);
});
</script>

<style lang="scss" src="./ContentController.scss"></style>
