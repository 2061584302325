<template>
  <SharedCasesContent :is-content-empty="isEmptyInventory" :is-bottom-empty="isEmptyInventory || isFirstPending">
    <template #top>
      <SharedText v-bind="changeXL(titleStyleDesktop, titleStyle)" class="title">
        {{ $t('quickSale.sellItems') }}
      </SharedText>
      <div class="top-buttons">
        <div class="top__buttons-group">
          <SharedButtonsGroup :border-radius="26" :direction="EButtonsGroupDirection.ROW" :gap="2">
            <SharedKitButton
              :color-preset="ColorPresets.QUICKSALE"
              :size-preset="actionButtonsSize"
              :type-preset="TypePresets.NONE"
              :text="$t('quickSale.selectAll')"
              class="top-btn"
              :is-disabled="isEmptyInventory"
              @click="emit('selected-all')"
            />
            <SharedKitButton
              :color-preset="ColorPresets.QUICKSALE"
              :size-preset="actionButtonsSize"
              :type-preset="TypePresets.NONE"
              :text="$t('quickSale.clearAll')"
              class="top-btn"
              :is-disabled="isEmptyInventory"
              @click="emit('cleared-all')"
            />
          </SharedButtonsGroup>
        </div>
      </div>
    </template>
    <template #middle>
      <NotActiveItems v-if="isEmptyInventory" />

      <div class="content-loader">
        <CaseLoader v-if="isPending && !allInventory.length" />
      </div>

      <transition-group name="list-item">
        <template v-if="!isEmptyInventory">
          <QsDropItem
            v-for="dropItem in allInventory"
            :key="dropItem.id"
            :inventory="dropItem"
            @selected-toggle="(id: number) => emit('selected-toggle', id)"
          />
          <div ref="inventoryLast"></div>
        </template>
      </transition-group>
    </template>
    <template #actions>
      <SharedKitButton
        v-if="!isPending && (isEmptyInventory || isFirstPending)"
        :color-preset="ColorPresets.PRIMARY"
        :size-preset="emptyButtonsSize"
        :type-preset="TypePresets.NONE"
        :text="$t('mainPage.getCasesMethods.method1Button')"
        class="empty__btn"
        @click="emit('redirect-to-open')"
      />
      <template v-else-if="!isPending || allInventory.length">
        <div v-if="isPending && allInventory.length" class="pagination__loader">
          <CaseLoader />
        </div>
        <SaleSummary
          :selected-count="quantitySelectedItems"
          :sale-sum="totalSumSelectedItems"
          :all-count="totalItems"
          :is-button-disabled="isPendingSell || !quantitySelectedItems"
          @sell="handleSell"
        />
        <SaleAllSkinButton :disabled="isPendingSell" :sum="totalSumItems" @sell-all="handleSellAll" />
      </template>
    </template>
  </SharedCasesContent>
</template>

<script setup lang="ts">
import QsDropItem from '../QsDropItem/QsDropItem.vue';
import NotActiveItems from '../NotActiveItems/NotActiveItems.vue';
import SaleSummary from '../SaleSummary/SaleSummary.vue';
import SaleAllSkinButton from '../SaleAllSkinButton/SaleAllSkinButton.vue';
import type { IQuickSaleContent, IQuickSaleContentEmits } from './QuickSaleContent.types';
import { titleStyle, titleStyleDesktop } from './QuickSaleContent.data';
import CaseLoader from '~/features/cases/components/CaseLoader/CaseLoader.vue';
import { SizePresets, ColorPresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { EButtonsGroupDirection } from '~/components/SharedButtonsGroup/SharedButtonsGroup.types';
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const props = defineProps<IQuickSaleContent>();

const emit = defineEmits<IQuickSaleContentEmits>();

const { allInventory, isPending, totalItems, quantitySelectedItems, totalSumSelectedItems, totalSumItems } =
  toRefs(props);

const actionButtonsSize = computed(() => changeXL(SizePresets.M, SizePresets.S));
const emptyButtonsSize = computed(() => changeXL(SizePresets.L, SizePresets.M));

const isEmptyInventory = computed(() => !allInventory.value.length && !isPending.value);
const isFirstPending = computed(() => isPending.value && !allInventory.value.length);

const handleSell = () => {
  emit('sell-selected-items');
};

const handleSellAll = () => {
  emit('sell-all-inventory');
};

const inventoryLast = ref<HTMLElement | undefined>();

const observer = new IntersectionObserver((data: IntersectionObserverEntry[]) => {
  if (data[0].isIntersecting) emit('fetchItem');
});

watch(inventoryLast, () => {
  if (!inventoryLast.value) return;
  observer.observe(inventoryLast.value);
});

onBeforeUnmount(() => {
  if (observer && inventoryLast.value) {
    observer.unobserve(inventoryLast.value);
    observer.disconnect();
  }
});
</script>

<style src="./QuickSaleContent.scss" scoped lang="scss"></style>
