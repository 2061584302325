export type TItemSource = 'case' | 'contract' | 'upgrade' | 'battle';

export interface ILiveDropBaseProperties {
  'Item Source': TItemSource;
}

export enum ELiveDropEvents {
  PROFILE_CLICKED = 'Live Feed - Profile Clicked',
  ITEM_CLICKED = 'Live Feed - Item Clicked',
}
