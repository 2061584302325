import type { ICasesItem } from '../Types/cases/client.types';
import { useAllCasesStore } from './allCases.store';

export const useCoinCasesStore = defineStore('mainPage/coinCases', () => {
  /* Imports */
  /* Стор всех кейсов, для получения коин кейсов */
  const allCasesStore = useAllCasesStore();

  /* Getters */
  /* Настройки названия секции */
  const titleOptions = computed(() => section.value?.titleOptions ?? undefined);

  /* Настройки цветов кейсов секции */
  const colorsOptions = computed(() => section.value?.colorsOptions ?? undefined);

  const section = computed(() => {
    return allCasesStore.sections.find((section) =>
      section.cases.every((item) => item.priceData.price && item.priceData.currency === 'COIN'),
    );
  });

  /* Отформатированный вид кейсов */
  const cases = computed<ICasesItem[]>(() => section.value?.cases ?? []);

  return {
    cases,
    colorsOptions,
    section,
    titleOptions,
  };
});
