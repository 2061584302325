<template>
  <div :class="['offer', rootClasses]">
    <div class="offer__overlay">
      <SharedKitLabel v-if="label" v-bind="label" />
      <SharedKitProgressCounter :variant="counterData" />
    </div>
    <NuxtImg provider="localProvider" class="offer__background" :src="background" />
    <div class="offer__info">
      <div class="offer__info-prices">
        <span class="offer__info-prices-new">{{ priceData.new }}</span>
        <span class="offer__info-prices-old">{{ priceData.old }}</span>
        <span class="offer__info-prices-profit">{{ priceData.profit }}</span>
      </div>
      <div class="offer__info-texts">
        <span class="offer__info-texts-name">{{ name }}</span>
        <span class="offer__info-texts-get">{{ $t('secretShop.offer.youGet') }}</span>
      </div>
    </div>
    <div class="offer__rewards">
      <SecretShopOfferMoney :money="reward.money" :coins="reward.coins" :coin-icon="coinIcon" />
      <SecretShopOfferCase v-for="caseData in reward.cases" :key="caseData.slug" v-bind="caseData" />
    </div>
    <div v-if="type === 'default'" class="offer__button">
      <SharedKitButton
        :color-preset="ColorPresets.PRIMARY"
        :type-preset="TypePresets.RECTANGLE"
        :size-preset="changeXL(SizePresets.L, SizePresets.M)"
        :text="$t('secretShop.offer.buy')"
        @click="$emit('buy')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IOfferProps, IOfferEmits } from './Offer.types';
import { useOfferData } from '~/features/secret-shop/composables/useOfferData';
import { ColorPresets, TypePresets, SizePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { useEventStore } from '~/store/event/event.store';

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const eventStore = useEventStore();
const { coinIcon } = storeToRefs(eventStore);

const props = withDefaults(defineProps<IOfferProps>(), {
  type: 'default',
});
const { price, restraints, type } = toRefs(props);

defineEmits<IOfferEmits>();

const rootClasses = computed(() => ({
  'offer--payment': type.value === 'payment',
}));

const { priceData, counterData } = useOfferData({ price, restraints });
</script>

<style scoped lang="scss" src="./Offer.scss"></style>
