import type { TApiPromo, IApiErrorPromo } from '~/features/payment/types/promo/api.types';
import type { IPromoInfo } from './client.types';

export const transform = (apiData: TApiPromo | IApiErrorPromo): IPromoInfo | undefined => {
  if ('success' in apiData || apiData.type === 'instant') return undefined;

  const isBlogger = apiData.type === 'blogger';
  const percent = apiData.type === 'common' ? apiData.percent : apiData.flexOffers[0].moneyFactor - 100;

  return {
    isBlogger,
    percent,
  };
};
