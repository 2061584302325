<template>
  <section class="daily-cases-controller">
    <p class="daily-cases-controller__title">{{ $t('freeCases.title') }}</p>
    <div v-if="dailyCasesStore.userInfo.userId" class="daily-cases-controller__container">
      <MainPageDailyCasesAvailableCases :user-info="dailyCasesStore.userInfo" />

      <MainPageDailyCasesProfile
        :user-info="dailyCasesStore.userInfo"
        @on-generate-level-click="handlerGenerateLevelClick"
        @on-open-cases="profileBtnClickHandler"
      />
    </div>
    <DailyCasesAuthWidgetController v-else />
  </section>
</template>

<script setup lang="ts">
import { useLevelGeneration } from '~/features/cases/composables/useLevelGeneration';
import DailyCasesAuthWidgetController from '~/features/cases/controllers/DailyCasesAuthWidgetController/DailyCasesAuthWidgetController.vue';
import { useDailyCasesStore } from '~/store/dailyCases/dailyCases.store';
import { DailyCaseEvents } from '~/repository/amplitude/events/dailyCases';

const { handlerGenerateLevelClick } = useLevelGeneration();
const dailyCasesStore = useDailyCasesStore();
const localeRoute = useLocaleRoute();

const profileBtnClickHandler = () => {
  DailyCaseEvents.openFreeClicked();
  return navigateTo(localeRoute(ROUTING.OPEN_CASE.DAILYCASES));
};
</script>

<style scoped lang="scss" src="./DailyCasesController.scss" />
