import { TIMES } from '~/constants/times.constants';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useUserStore } from '~/store/user/user.store';
import type { UTMParams } from '~/types/utm.types';

const LOCAL_STORAGE_KEY = 'utm_tags';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    const authStore = useAuthStore();

    const utmTagsCookie = useCookie(LOCAL_STORAGE_KEY, { default: () => '' });

    const { $utm } = useNuxtApp();
    const userStore = useUserStore();

    const route = useRoute();
    const clickId =
      (route.query.click_id as string | undefined) ??
      utmTagsCookie.value
        .split('&')
        .find((param) => param.startsWith('click_id='))
        ?.split('=')[1];

    const now = Date.now();
    const [validUtm] = $utm.value.filter((utm) => {
      const utmTime = new Date(utm.timestamp).getTime();
      return now - utmTime < TIMES.MONTH;
    });

    const queryData = [];
    if (clickId) {
      queryData.push(`click_id=${clickId}`);
    }

    const validUtmParams = validUtm?.utmParams ?? {};

    let key: keyof UTMParams;
    for (key in validUtmParams) {
      if (!validUtm.utmParams[key]) continue;
      queryData.push(`${key}=${validUtm.utmParams[key]}`);
    }

    if (!queryData.length) return;

    const unifiedQueryData = queryData.join('&');
    utmTagsCookie.value = unifiedQueryData;

    if (!authStore.isAuth) return;

    const { origin, pathname } = location;
    const fullUrl = `${origin}${pathname}?${unifiedQueryData}`;

    userStore.postUserSession(fullUrl);
  });
});
