import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useLazyAsyncData } from '#app';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { AlertCodes } from '~/store/alert/alert.messages';
import { useAlertStore } from '~/store/alert/alert.store';
import { useUserStore } from '~/store/user/user.store';
import {
  EGroupSortParams,
  EInventoryItemStatus,
  type IInventory,
  type IInventoryPackContent,
  type IInventorySendItemResponseErrorDTO,
  type IReplaceInventoryItemRequestDTO,
  type TGetInventoryRequestDTO,
  type TGetInventoryResponseDTO,
  type TGetReplacementInventoryItemsRequestDTO,
  type TInventorySendItemResponseDTO,
} from '~/repository/modules/inventory/inventory.types';
import { usePagination } from '~/composables/usePagination';
import { dropItemTypes } from '~/types/cases/dropItem';
import type { IInventoryToUpgrades } from '~/store/inventory/types';
import { TIMES } from '~/constants/times.constants';
import type { IDefaultError } from '~/api/global/api.types';

export const useInventoryStore = defineStore('inventory', () => {
  const {
    $api,
    $i18n: { t, te },
  } = useNuxtApp();
  const alertStore = useAlertStore();
  const userStore = useUserStore();

  const route = useRoute();
  const { user, isUserLoaded } = storeToRefs(userStore);

  // Является ли инвентарь собственным
  const isCurrentUser = computed(() => {
    return Number(route.params.userId) === user.value?.userId;
  });

  // инвентарь пользователя
  const userInventory = ref<IInventory[]>([]);
  // Все предметы или только активные предметы
  const isAllInventory = ref(false);
  // Общая стоимость предметов в инвентаре
  const totalPrice = ref<string>('');
  // Предметы под замену
  const tradeItems = ref<IInventory[]>([]);
  // можно ли вывести предмет
  const isTakeItemAvailable = ref<boolean>(true);
  // список предметов для замены
  const changeItemList = ref<IInventory[]>([]);
  // предмет, который хотят обменять
  const currentTradeItem = ref<IInventory>();
  // предмет, на который хотят обменять
  const currentExchangeTradeItem = ref<IInventory | null>();
  // состояние видимости Попапа замены предмета
  const isExchangePopupVisible = ref<boolean>(false);
  // время доступности попапа замены
  const currentInventoryExchangePopupTime = ref();
  // выбранный инвентарь
  const selectedInventory = computed<IInventory[]>(() => {
    return userInventory.value.filter((el) => el.isSelected);
  });
  // id выбранных предметов
  const selectedInventoryIds = computed(() => selectedInventory.value.map((item) => item.id));
  // количество выбранных предметов
  const quantitySelectedItems = computed(() => selectedInventory.value.length);
  // общая сумма выбранных предметов
  const totalSumSelectedItems = computed(() =>
    selectedInventory.value.reduce((acc, value) => acc + +value.priceProfile, 0),
  );
  // общая сумма выбранных предметов для вывода
  const totalSumSelectedItemsForTake = computed(() =>
    selectedInventory.value.reduce((acc, value) => acc + +value.priceProfile, 0),
  );

  // Информация по попаданию предметов в апгрейды
  const inventoryToUpgrades = computed<IInventoryToUpgrades>(() => {
    const filteredItems = userInventory.value
      .filter((item) => item.status === EInventoryItemStatus.PROGRESS)
      .slice(0, 15);

    const ids = filteredItems.map((item) => item.id);
    const totalPrice = filteredItems.reduce((sum, item) => sum + Number(item.priceProfile), 0);

    return {
      ids,
      totalPrice,
    };
  });

  const ChangeItemListEntity = computed(() => {
    return changeItemList.value.length;
  });

  // Показывать только type 3 предметы в списке предметов
  const isShowOnlyType3 = computed<boolean>(
    () => !!userInventory.value.find((el) => el.type === dropItemTypes['3'] && el.isSelected),
  );

  // Отображать список предметов для пользователя, в зависимости выбран ли на текущий момент для вывод type 3 предмет
  // Если выбран type3 предмет, то для удобства остальные предметы скрываются
  const displayedUserInventory = computed<IInventory[]>(() => {
    return isShowOnlyType3.value
      ? userInventory.value.filter((el) => el.type === dropItemTypes['3'])
      : userInventory.value;
  });

  // Общее количество активных предметов
  const totalActiveItems = ref(0);

  // Инициализация пагинации
  const total = ref(0);
  const pagination = usePagination({
    onLoadMore: async () => {
      await refreshInventory();
    },
    page: 1,
    pageSize: 45,
    total,
  });

  // Максимальное количество предметов type3 доступных к выводу
  const capSelectedItems = ref<number>(10);

  // Управление состоянием выбранности предмета
  const toggleSelectItemState = (item: IInventory) => {
    if (item.type !== dropItemTypes['3']) return;

    if (item.isSelected) {
      item.isSelected = false;
      return;
    }

    if (selectedInventory.value.length < capSelectedItems.value) {
      item.isSelected = true;
    }
  };

  // выбрать все
  const selectedAllItem = () => {
    userInventory.value.forEach((el) => {
      el.isSelected = true;
    });
  };
  // сбросить все
  const removeAllFromSelected = () => {
    userInventory.value.forEach((el) => {
      el.isSelected = false;
    });
  };

  // Управляем состоянием попапа
  const toggleExchangePopup = () => {
    isExchangePopupVisible.value = !isExchangePopupVisible.value;
  };

  // устанавливает предмет, который хотим вывести
  const setCurrentTradeItem = (item: IInventory) => {
    currentTradeItem.value = item;
  };

  const fetchInventory = async (params: TGetInventoryRequestDTO): Promise<TGetInventoryResponseDTO> => {
    try {
      return await $api.inventory.getInventory(params);
    } catch (e) {
      if (pagination.currentPage.value > 1) {
        alertStore.showError({
          message: (e as { msg: string | undefined }).msg || '',
          title: t('profile.errors.inventoryLoadMore'),
        });
      } else {
        alertStore.showError({
          message: (e as { msg: string | undefined }).msg || '',
          title: t('profile.errors.inventoryFetch'),
        });
      }
      throw e;
    }
  };

  const inventoryGroup = computed<EGroupSortParams>(() => {
    if (!isCurrentUser.value) {
      return EGroupSortParams.ALL;
    } else if (isAllInventory.value) {
      return EGroupSortParams.ALL;
    } else {
      return EGroupSortParams.PROFILE;
    }
  });

  const {
    data: inventoryData,
    pending: inventoryPending,
    refresh: refreshInventory,
  } = useLazyAsyncData<TGetInventoryResponseDTO>(
    'get-inventory',
    async () => {
      const params: TGetInventoryRequestDTO = {
        group: inventoryGroup.value,
        page: pagination.currentPage.value,
        perPage: pagination.currentPageSize.value,
        ...(Number(route.params.userId) && { userId: Number(route.params.userId) }),
      };

      const response = await fetchInventory(params);

      if (response.data.items) {
        const uniqueItems = response.data.items.filter(
          (item) => !userInventory.value.some((existingItem) => existingItem.id === item.id),
        );

        userInventory.value.push(...uniqueItems);

        totalActiveItems.value =
          params.group === EGroupSortParams.PROFILE ? response.data.totalItem : totalActiveItems.value;
        total.value = response.data.totalItem;
        totalPrice.value = response.data.totalPrice;
      }

      return response;
    },
    {
      default: () => ({}) as TGetInventoryResponseDTO,
      immediate: false,
    },
  );

  const fetchInventoryItemReplacements = async (params: TGetReplacementInventoryItemsRequestDTO) => {
    return await $api.inventory.getReplacementItems(params);
  };

  watch(
    isUserLoaded,
    async (isLoaded) => {
      if (isLoaded) {
        await refreshInventory();
      }
    },
    {
      immediate: true,
    },
  );

  watch(isAllInventory, async () => {
    userInventory.value = [];
    pagination.currentPage.value = 1;
    await refreshInventory();
  });

  // Список предметов в превью
  const previewItems = ref<IInventoryPackContent[]>([]);
  // Видимость попапа с предметами из пака
  const isPreviewItemsPopupVisible = ref<boolean>(false);
  // Управление состоянием попапа
  const togglePreviewItemsPopup = () => {
    isPreviewItemsPopupVisible.value = !isPreviewItemsPopupVisible.value;
  };

  const unpackItem = async (inventory: IInventory) => {
    if (!inventory.packItems || inventory.status === EInventoryItemStatus.UNPACKED) return;
    const response = await $api.inventory.unpackItems({ inventoryId: inventory.id });
    const packItems = response.data;
    const animationDuration = packItems.length <= 7 ? 2000 : 4000;
    const interval = animationDuration / packItems.length;

    let addedItems = 0;

    const intervalId = setInterval(() => {
      if (addedItems < packItems.length && packItems[addedItems]) {
        userInventory.value.unshift({
          ...packItems[addedItems],
          status: EInventoryItemStatus.PROGRESS,
        });

        addedItems++;
      }

      if (addedItems >= packItems.length) {
        clearInterval(intervalId);
      }
    }, interval);

    inventory.status = EInventoryItemStatus.UNPACKED;
  };

  // продать предмет
  const sellItem = async (inventory: IInventory) => {
    const prevStatus = inventory.status;
    try {
      inventory.status = EInventoryItemStatus.ACCEPTED;
      await $api.inventory.sellManyInventory([inventory.id]);
      alertStore.show({
        title: t('alerts.itemInSaleQueue'),
        type: 'success',
      });
    } catch {
      inventory.status = prevStatus;
    }
  };

  // продать все предметы
  const sellAllItems = async () => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        await $api.inventory.sellAllItems();

        const totalItemValue: number = user?.value?.finance?.totalAmountItems
          ? +user?.value?.finance?.totalAmountItems
          : 0;

        userStore.increaseBalanceOn(totalItemValue);
        userStore.decrementTotalAmountItems(totalItemValue);

        alertStore.show({
          title: AlertCodes.ALL_ITEMS_IN_SALE_QUEUE,
          type: 'success',
        });
      },
      (e) => {
        alertStore.showError({
          // @ts-expect-error TODO remove or refactor
          message: e,
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
    );
  };

  // заменить предмет на другие предметы
  const tradeItem = (id: number) => {
    return $api.inventory.tradeItem(id);
  };

  const updateInventoryItems = (itemIds: number[], updates: Partial<IInventory>) => {
    userInventory.value = userInventory.value.map((item) =>
      itemIds.includes(item.id) ? { ...item, ...updates } : item,
    );
  };

  const handleError = (message: string, itemIds: number[]) => {
    const hasTranslation = te(message);

    const title = hasTranslation ? t(message) : ErrorCodes.UNPREDICTED_EXCEPTION;
    const parsedMessage = hasTranslation ? '' : message;

    alertStore.showError({ title, message: parsedMessage });

    updateInventoryItems(itemIds, { status: EInventoryItemStatus.PROGRESS });
  };

  // Запрос на вывод скина
  const sendItem = async (ids: number | number[], method?: string): Promise<TInventorySendItemResponseDTO> => {
    const itemIds = Array.isArray(ids) ? ids : [ids];

    updateInventoryItems(itemIds, {
      status: EInventoryItemStatus.PREPARING,
      timerData: (TIMES.MINUTE * 5) / 1000,
    });

    try {
      const response = await $api.inventory.sendItem(itemIds, method);
      if (response.status && 'status' in response.data && response.data.status) {
        alertStore.show({
          type: 'success',
          title: AlertCodes.ITEM_IN_WITHDRAWAL_QUEUE,
        });
      } else {
        const { code, parameters } = response.data as IInventorySendItemResponseErrorDTO;
        const errorText = t(`errors.profileCodes.${code}`, parameters);

        handleError(errorText, itemIds);
      }

      return response;
    } catch (err) {
      const typedError = err as IDefaultError<{ data: IInventorySendItemResponseErrorDTO }>;

      const { code, parameters } = typedError.data?.data as IInventorySendItemResponseErrorDTO;
      const hasText = te(`errors.profileCodes.${code}`);

      if (!hasText) {
        handleError(ErrorCodes.UNPREDICTED_EXCEPTION, itemIds);
      } else {
        const errorText = t(`errors.profileCodes.${code}`, parameters);
        handleError(errorText, itemIds);
      }

      return {} as TInventorySendItemResponseDTO;
    }
  };

  const sellDrop = async (id: string): Promise<void> => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        await $api.inventory.sellDrop(id);
        alertStore.show({ title: AlertCodes.ITEM_IN_SALE_QUEUE, type: 'success' });
      },
      (e) => {
        alertStore.showError({
          message: e.msg,
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
    );
  };

  // Замена предмета
  const replaceItem = async (params: IReplaceInventoryItemRequestDTO) => {
    await GlobalUtils.Api.handleRequest(async () => {
      const response = await $api.inventory.replaceItem(params);
      if (response.data.status) {
        pagination.currentPage.value = 1;
        userInventory.value = [];
        await refreshInventory();
      }
    });
  };

  return {
    ChangeItemListEntity,
    changeItemList,
    currentExchangeTradeItem,
    currentTradeItem,
    inventoryData,
    inventoryPending,
    isAllInventory,
    isExchangePopupVisible,
    isTakeItemAvailable,
    pagination,
    quantitySelectedItems,
    refreshInventory,
    removeAllFromSelected,
    selectedAllItem,
    selectedInventory,
    sellAllItems,
    sellDrop,
    sellItem,
    sendItem,
    setCurrentTradeItem,
    toggleExchangePopup,
    toggleSelectItemState,
    total,
    totalPrice,
    totalSumSelectedItems,
    tradeItem,
    tradeItems,
    displayedUserInventory,
    userInventory,
    isCurrentUser,
    selectedInventoryIds,
    replaceItem,
    currentInventoryExchangePopupTime,
    totalActiveItems,
    inventoryToUpgrades,
    fetchInventoryItemReplacements,
    isShowOnlyType3,
    totalSumSelectedItemsForTake,
    capSelectedItems,
    unpackItem,
    togglePreviewItemsPopup,
    isPreviewItemsPopupVisible,
    previewItems,
  };
});
