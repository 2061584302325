import type { IAppRoute } from '../../utils/routing.utils';
import { FAQ_ROUTES } from './routes/faq.routes';
import { BATTLES_ROUTES } from './routes/battles.routes';
import { CARDFORM_ROUTES } from './routes/cardForm.routes';
import { HAMSTER_ROUTES } from './routes/hamster.routes';
import { MAINPAGE_ROUTES } from './routes/mainPage.routes';
import { OPEN_CASE_ROUTES } from './routes/openCase.routes';
import { PAYMENT_ROUTES } from './routes/payment.routes';
import { TECHIES_ROUTES } from './routes/techies.routes';
import { PROFILE_ROUTES } from './routes/profile.routes';
import { QUIZ_ROUTES } from './routes/quiz.routes';
import { SECRETSHOP_ROUTES } from './routes/secret-shop.routes';
import { PROVABLY_FAIR_ROUTES } from './routes/provably-fair.routes';
import { FOOTER_PAGES_ROUTES } from './routes/footer-pages.routes';
import { UI_KIT_ROUTES } from './routes/ui-kit.routes';
import { UPGRADES_ROUTES } from './routes/upgrades.routes';
import { TEST_ROUTES } from './routes/test.routes';

const ROOT_ROUTES = {
  BATTLES: '/battles',
  CARDFORM: '/cardform',
  FAQ: '/faq',
  PROVABLY_FAIR: '/provably-fair',
  FOOTER_PAGES: '',
  HAMSTER: '/hamster',
  MAINPAGE: '',
  OPEN_CASE: '/open',
  PAYMENT: '/refill',
  SECRETSHOP: '/offersshop',
  PROFILE: '/profile',
  QUIZ: '/quiz',
  TECHIES: '/techies',
  UI_KIT: '/ui-kit',
  UPGRADES: '/upgrades',
  TEST: '/test',
};

const ROUTING = {
  BATTLES: wrapByRootRoute(ROOT_ROUTES.BATTLES, BATTLES_ROUTES),
  CARDFORM: wrapByRootRoute(ROOT_ROUTES.CARDFORM, CARDFORM_ROUTES),
  FAQ: wrapByRootRoute(ROOT_ROUTES.FAQ, FAQ_ROUTES),
  PROVABLY_FAIR: wrapByRootRoute(ROOT_ROUTES.PROVABLY_FAIR, PROVABLY_FAIR_ROUTES),
  FOOTER_PAGES: wrapByRootRoute(ROOT_ROUTES.FOOTER_PAGES, FOOTER_PAGES_ROUTES),
  HAMSTER: wrapByRootRoute(ROOT_ROUTES.HAMSTER, HAMSTER_ROUTES),
  MAINPAGE: wrapByRootRoute(ROOT_ROUTES.MAINPAGE, MAINPAGE_ROUTES),
  OPEN_CASE: wrapByRootRoute(ROOT_ROUTES.OPEN_CASE, OPEN_CASE_ROUTES),
  PAYMENT: wrapByRootRoute(ROOT_ROUTES.PAYMENT, PAYMENT_ROUTES),
  SECRETSHOP: wrapByRootRoute(ROOT_ROUTES.SECRETSHOP, SECRETSHOP_ROUTES),
  PROFILE: wrapByRootRoute(ROOT_ROUTES.PROFILE, PROFILE_ROUTES),
  QUIZ: wrapByRootRoute(ROOT_ROUTES.QUIZ, QUIZ_ROUTES),
  TECHIES: wrapByRootRoute(ROOT_ROUTES.TECHIES, TECHIES_ROUTES),
  UI_KIT: wrapByRootRoute(ROOT_ROUTES.UI_KIT, UI_KIT_ROUTES),
  UPGRADES: wrapByRootRoute(ROOT_ROUTES.UPGRADES, UPGRADES_ROUTES),
  TEST: wrapByRootRoute(ROOT_ROUTES.TEST, TEST_ROUTES),
};

export default ROUTING;

export function wrapByRootRoute<T>(root: string, routes: T): T {
  const wrappedRoutes = {} as T;

  for (const routeKey in routes) {
    const routeConfig = routes[routeKey];
    const anyRouteKey = routeKey as string;

    /* @ts-expect-error TODO remove or refactor */
    wrappedRoutes[anyRouteKey as keyof T] = {
      notNative: true,
    } as IAppRoute;

    for (const key in routeConfig) {
      const fieldValue = routeConfig[key];

      if (key === 'getDynamicPath' && typeof fieldValue === 'function') {
        /* @ts-expect-error TODO remove or refactor */
        wrappedRoutes[routeKey][key] = (...args: Array<number | string>) => root + fieldValue(...args);
      } else if (key === 'path' || key === 'alias') {
        /* @ts-expect-error TODO remove or refactor */
        wrappedRoutes[routeKey][key] = root + fieldValue;
      } else {
        wrappedRoutes[routeKey][key] = fieldValue;
      }
    }
  }

  return wrappedRoutes;
}
