<script setup lang="ts">
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { PacksBannerButtonColorPreset } from '~/features/mainPage/components/PacksBanner/PacksBanner.data';
import bannerPacks from '~/assets/images/mainPage/banner-packs.png';

const { t } = useNuxtApp().$i18n;
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const isPackPopupVisible = ref<boolean>(false);
</script>

<template>
  <div class="packs-banner">
    <NuxtImg :src="bannerPacks" class="packs-banner__image packs-banner__image--left" provider="localProvider" />
    <div class="packs-banner__content">
      <div class="packs-banner__content-label">
        {{ $t('mainPage.packsBanner.label') }}
      </div>
      <SharedKitButton
        class="packs-banner__content-button"
        :text="t('mainPage.packsBanner.btnText')"
        :color-preset="PacksBannerButtonColorPreset"
        :size-preset="changeXL(SizePresets.L, SizePresets.S)"
        :type-preset="TypePresets.ROUND"
        @click="isPackPopupVisible = true"
      />
    </div>
    <NuxtImg :src="bannerPacks" class="packs-banner__image packs-banner__image--right" provider="localProvider" />
    <MainPagePacksPopup v-model="isPackPopupVisible" />
  </div>
</template>

<style scoped lang="scss" src="./PacksBanner.scss" />
